import { ItemValidation, ListItem } from './interfaces';

export function makeArray(i: number): number[] {
    return new Array(i);
}

export function scoreSameValues(selectedDices: number[], itemValidation: ItemValidation, item: ListItem, fixedPoints?: number[]): number {

    let dices = [1,2,3,4,5,6]
    let score = 0;
    let numberValid = [];
    let valid = true;
    selectedDices = orderBy(selectedDices);
    const uniqueNumbers = itemValidation.numberOfSameDices.filter((item, i, ar) => ar.indexOf(item) === i).length;

    if (itemValidation.numberOfSameDices) {

        let numberIndex = 0;
        while (numberIndex < itemValidation.numberOfSameDices.length) {
            let numberValidSet = false;

            if (valid) {
                const number = itemValidation.numberOfSameDices[numberIndex];

                for  (const dice of dices) {       
                    const diceCount = selectedDices.filter((sd) => sd == dice).length;

                    if (diceCount >= number) {
                        const scoreTemp = dice * number;
                        
                        if (numberValidSet == false) {
                            numberValid.push({sameValues: diceCount, required: number, valid: true, score: scoreTemp});
                            numberValidSet = true;
                        } else {
                            numberValid[numberIndex]  = {sameValues: diceCount, required: number, valid: true, score: scoreTemp};
                        }

                        dices = dices.filter(d => d !== dice);
                        
                        if (uniqueNumbers == 1) {
                            dices = setDicesTill(dice);
                        }
                    }
                };
            }
            if (numberValid.length == 0) {
                valid = false;
            }
            numberIndex ++;
        };

        const numberValidItems = numberValid.filter(nv => nv.sameValues >= itemValidation.numberOfSameDices.length);
        if (numberValidItems.length >= itemValidation.numberOfSameDices.length) {
            numberValidItems.forEach((item) => {
                score += item.score;
            });
        }
    }

    if (score > 0 && itemValidation.countAllDices) {
        score = sumOfNumberedArray(selectedDices)
    } else if (score > 0 && itemValidation?.fixedScore) {
        score = fixedPoints[0];
    }
    return score;
}


export function setDicesTill(dice): number[] {
    let item = 1;
    let dices = [];
    while (item < dice) {
        dices.push(item);
        item++;
    }
    return dices;
}

export function orderBy(array: any[], sortOrder?: string, property?: string): any[] {  
    const sort = sortOrder?.toLowerCase() == 'desc' ? -1 : 1;
    if (property) {
        return array.sort((a,b) => {
            return a[property] > b[property] ? sort : a[property] < b[property] ? (-1 * sort) : 0;
        })
    } else {
        return array.sort((a,b) => {
            return a > b ? sort : a < b ? (-1 * sort) : 0;
        })
    }
}

export function sumOfNumberedArray(array: number[]) {
    return array.reduce((a, b) => {
        return a + b;
    });
}

export function setSelectedLanguage(lang: string): JSON {
     return this.httpClient.get("assets/lang/" + lang + ".json").subscribe((data: JSON) =>{
        return this.storage.saveSelectedLanguage(data);
    })
  }

