import { Injectable } from '@angular/core';
import { DiceSettings, Game, List, Player } from './interfaces';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
    constructor() {}

    activeGame = 'active-game';
    savedGame = 'saved-game';
    gameType = 'gameType';
    gameList = 'gameList';
    numberOfLists = 'numberOfLists';
    diceSettings = 'diceSettings';
    players = 'players';
    langCode = 'lang';

    // Game
    loadGame(fileName?): Game {
        const name = fileName || this.activeGame;
        return JSON.parse(localStorage.getItem(name));
    }

    saveGame(game: Game, fileName?: string): void {
        const name = fileName || this.activeGame;
        localStorage.setItem(name, JSON.stringify(game));
    }

    deleteGame(fileName?: string): void {
        const name = fileName || this.activeGame;
        localStorage.removeItem(name);
    }

    // Players
    loadPlayers(): Player[] {
        return JSON.parse(localStorage.getItem(this.players));
    }

    async savePlayers(players: Player[]): Promise<void> {
        localStorage.setItem(this.players, JSON.stringify(players));
    }

    deleteDiceSettings(fileName?: string): void {
        const name = fileName || this.diceSettings;
        localStorage.removeItem(name);
    }

    saveDiceSettings(diceSettings: DiceSettings, fileName?: string): void {
        const name = fileName || this.diceSettings;
        localStorage.setItem(name, JSON.stringify(diceSettings));
    }

    loadDiceSettings(fileName?: string): DiceSettings {
        const name = fileName || this.diceSettings;
        return JSON.parse(localStorage.getItem(name));
    }

    // GameType
    loadGameType(): string {
        return localStorage.getItem(this.gameType);
    }

    saveGameType(gameType: string): void {
        localStorage.setItem(this.gameType, gameType);
    }

    loadGameList(): List {
        return JSON.parse(localStorage.getItem(this.gameList));
    }

    saveGameList(list: List): void {
        localStorage.setItem(this.gameList, JSON.stringify(list));
    }

    // NumberOfLists
    loadNumberOfLists(): number {
        return +localStorage.getItem(this.numberOfLists);
    }
        
	saveNumberOfLists(value: number): void {
		localStorage.setItem(this.numberOfLists, value.toString());
    }

    // Languages
    loadSelectedLanguage(lang: string): JSON {
        return JSON.parse(localStorage.getItem(lang));
    }

    saveSelectedLanguage(lang: JSON): void {
        localStorage.setItem(this.langCode, JSON.stringify(lang));
    }

}
