import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import {List, CalculationType, Game, GamePlayerColumn, Player, ColumnScore, DiceSettings, DicesSameValues } from '../interfaces';
import { faHome, faDice, faList, faUndo } from '@fortawesome/free-solid-svg-icons';
import { LocalStorageService } from './../storage.service';
import * as GlobalFunctions from './../functions';

@Component({
	selector: 'app-game-board',
	templateUrl: './game-board.component.html',
	styleUrls: [ './game-board.component.scss' ]
})

export class GameBoardComponent implements OnInit, OnChanges {

	constructor(private storage: LocalStorageService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.diceScore && this.game) {
			this.diceScore = changes.diceScore.currentValue;
			this.calculateAll();
		}
	}

	@ViewChild('popupEndGame') popupEndGame: HTMLElement;
	
	@Input() diceScore: DiceSettings;

	@Output() restartGame = new EventEmitter<boolean>();
	@Output() showDiceBoard = new EventEmitter<boolean>();
	@Output() resetDiceBoard = new EventEmitter<boolean>();
	@Output() undoDiceBoard = new EventEmitter<boolean>();
	
	game: Game;
	calculationType = CalculationType;
	totalRounds: number;
	showUndo: boolean;

	translations: JSON;

	bonus = 0;
	currentRound = 0;
	initialCalculateTimeOut = 700;
	filledItemsInColTemp = 0;

	isInitialCalc = true;
	isShowDiceBoard = false;
	showSuggestions = false;

	faHome = faHome;
	faDice = faDice;
	faList = faList;
	faUndo = faUndo;

	makeArray = GlobalFunctions.makeArray;

	setUndoDiceBoard() {
		this.game = this.storage.loadGame('gamePrevious');
		this.diceScore = this.storage.loadDiceSettings('diceSettingsPrevious');
		this.undoDiceBoard.emit(!this.undoDiceBoard);
		this.storage.saveDiceSettings(this.diceScore);
		this.showUndo = false;
	}

	async fillColField(col: ColumnScore, playerIndex: number, playerScore: number) {
		
		this.storage.saveGame(this.game, 'gamePrevious');
		this.storage.saveDiceSettings(this.diceScore, 'diceSettingsPrevious');
		this.showUndo = true;
		col.value = playerScore
		
		this.saveAndCalculateFilledCol(playerIndex);
		this.resetSuggestions();
	}

	async saveAndCalculateFilledCol(playerIndex: number): Promise<void> {
		this.storage.saveGame(this.game);
		this.calculateAll();
		this.hidePopUp(); 
		this.showSuggestions = false;
		this.resetDiceBoard.emit(!this.resetDiceBoard);
		await this.checkGameFinished();
		this.setNextPlayerIndex(playerIndex);
	}

	async checkGameFinished() {
		let round = 0;
		let totalRounds = 0;
		this.game.gameList.listParts.forEach((part) => {
			totalRounds += part.listItems.length * this.game.players.length * part.listItems[0].playerscores[0].columns.length;
		})
		this.game.players.forEach((player) =>{
			round += player.currentRound;
		});
		if (round == totalRounds) {
			document.getElementById('end-game-popup').className = 'popup';
			document.getElementById('overLayer').style.display = 'block';
			this.showUndo = false;
		}
		
	}

	showPopupEndGame(isPreview?: boolean): void {
		document.getElementById('end-game-popup').className = 'popup';
		document.getElementById('overLayer').style.display = 'block';

		if (isPreview) {
			document.getElementById('btn-end-game').style.display = 'none';
			document.getElementById('title-end-game').innerHTML = 'Tussenstand';
		}
	}

	resetScore(col: ColumnScore): void {

		col.value = null;

		this.calculateAll();
		this.storage.saveGame(this.game);
		this.hidePopUp();
	}

	calculateAll(): void {

		// loop through players of game
		for (let playerIndex = 0; playerIndex < this.game.players.length; playerIndex++) {
			this.game.players[playerIndex].currentRound = 0;

			// loop through lists of player
			for  (let colIndex = 0; colIndex < this.game.numberOfGameLists; colIndex++) {
				let partIndex = 0;
				this.totalRounds = 0;
	
				// loop through parts of list
				this.game.gameList.listParts.forEach((part) => {

					// set totalrounds  -- TODO this is calculated way to often.
					this.totalRounds += part.listItems.length;
	
					const idName = 'list-field-' + playerIndex + '-' + partIndex + '-' + colIndex;
					const idCalculateCols: NodeListOf<HTMLInputElement> = document.querySelectorAll('[id^="' + idName + '"][id$="-value"]');
					const idColstotal: NodeListOf<HTMLInputElement> = document.querySelectorAll('[id^="list-field-' + playerIndex + '"][id$="' + colIndex + '-total"]');
	
					// calculate all items in col of part
					this.calculateCol(idCalculateCols, idColstotal, idName, colIndex, partIndex, playerIndex);
	
					partIndex++;
				});			
			}
		}

		if (this.diceScore && !this.isInitialCalc) {
			this.showSuggestions = true;
		}

		// Game is initially calculated
		this.isInitialCalc = false;

		this.checkGameFinished();
	}

	calculateCol(idCols: NodeListOf<HTMLInputElement>, idColstotal: NodeListOf<HTMLInputElement>, idBase: string, colIndex: number, partIndex: number, playerIndex: number): void {

		const total = this.calculateTotalofColItems(playerIndex, colIndex, partIndex);


		// check filled items of column
		const filledItemsInCol = this.filledItemsInColTemp;

		this.filledItemsInColTemp = 0;
	
		const elTotal = (<HTMLInputElement>document.getElementById(idBase + '-total'));
		
		if (document.getElementById(idBase + '-bonus')) {
			
			const bonusTarget = this.game.gameList.listParts[partIndex].bonusTarget;
			const bonusValue = this.game.gameList.listParts[partIndex].bonusValue;


			let remainingPointsForBonus = (bonusTarget - total) * -1;

			const totalSignClass = remainingPointsForBonus < 0  ? 'error' : remainingPointsForBonus > 0 ? 'success' : 'info';

			const elSubtotalSign = (<HTMLInputElement>document.getElementById(idBase + '-subtotal-sign'));
			const elSubTotal = (<HTMLInputElement>document.getElementById(idBase + '-subtotal'));
			const elBonus = (<HTMLInputElement>document.getElementById(idBase + '-bonus'));

			elSubtotalSign.className = 'sign ' + totalSignClass;
			elBonus.className = 'info';

			if (filledItemsInCol > 0 && filledItemsInCol < idCols.length ) {
				elSubtotalSign.className = 'total sign ' + totalSignClass;
				elSubtotalSign.innerHTML = remainingPointsForBonus.toString(); 
			}
			
			if (filledItemsInCol == idCols.length) {
				if (total < bonusTarget) {
				elBonus.className = 'error';
				}
			}

			elSubTotal.value = total.toString();
			elBonus.value = '0';
			elTotal.value = total.toString();

			if (total > bonusTarget - 1) {
					elBonus.value = bonusValue.toString();
					elBonus.className = 'success';
					elTotal.value = (total + bonusValue).toString();
			}
		} else {
			elTotal.value = total.toString();
		}

		// total general (sum of all parts)
		let totalGeneral = 0;
		idColstotal.forEach((elementGeneral: HTMLInputElement) => {
			totalGeneral += elementGeneral.value ? +elementGeneral.value : 0;
		});

		(<HTMLInputElement>document.getElementById('list-field-' + playerIndex + '-' + colIndex + '-totalgeneral'))
			.value = totalGeneral.toString();
	}

	calculateTotalofColItems(playerIndex: number, colIndex: number, partIndex: number): number {
		let total = 0;    
		this.game.gameList.listParts[partIndex].listItems.forEach(async (item, itemIndex) => {

			const playerItemScore = item.playerscores[playerIndex].columns[colIndex]
			const score = playerItemScore.value;
			total += score;
			
			if (score !== null) {
				this.game.players[playerIndex].currentRound++;
				this.filledItemsInColTemp++;
			} else {

				// calculate suggestions for thrown dices
				if (this.diceScore) {
					playerItemScore.suggestionScore = this.checkDiceScores(partIndex, itemIndex);
				}
			}				
		});
		return total;
	}

	checkDiceScores(partIndex: number, itemIndex: number): number {

		let score = 0;
		let selectedDices =  [...this.diceScore.thrownDices];
		const holdDices = [...this.diceScore.holdDices];

		holdDices.forEach((dice, index) => {
			if (dice) {
				selectedDices[index] = dice;
			}
		});

		const item = this.game.gameList.listParts[partIndex].listItems[itemIndex];
		const itemValidation = item.validation;

		switch(item.calculationType) {
			case 1 : 
				const numberOfDiceValues = selectedDices.filter(val => val == item.value).length;
				score = numberOfDiceValues * item.value;
				return score;
			case 2 :
				if (itemValidation) {

					if (itemValidation.possibleCombinations) {
						selectedDices = selectedDices.sort((a,b) => {
							return a > b ? 1 : a < b ? -1 : 0;
						});
						selectedDices = selectedDices.filter((c, index) => {
							return selectedDices.indexOf(c) === index;
						});

						let dicesString = selectedDices.toString();
						itemValidation.possibleCombinations.forEach((diceValues) => {
							if (dicesString.includes(diceValues.toString())) {
								score = item.fixedPoints[0];
							}
						});		
					}
					if (itemValidation.numberOfSameDices) {
						score = GlobalFunctions.scoreSameValues(selectedDices, itemValidation, item, item.fixedPoints);
					}
				}
				return score;

			default :
				return score;
		}
	}

	showSuggestion(value: number): boolean {
		return value == null && this.showDiceBoard ? true : false;
	}

	resetSuggestions() {
		this.game.gameList.listParts.forEach((part) => {
			part.listItems.forEach((item) => {
				item.playerscores.forEach((playerScore) => {
					playerScore.columns.forEach((col) => {
						col.suggestionScore = 0;
					})
				})
			});
		});
	}

	showResetItem(value: number): boolean {
		return value == null ? false : true;
	}

	setPopupRowTotal(playerIndex: number, colIndex: number): string {
		return (<HTMLInputElement>document.getElementById('list-field-' + playerIndex + '-' + colIndex + '-totalgeneral')).value;
	}

	setTotalofCols(playerIndex: number): string {
		let value = 0;
		const idTotalGeneral: NodeListOf<HTMLInputElement> = document.querySelectorAll('[id$="' + playerIndex + '-totalgeneral-end"]');
		idTotalGeneral.forEach(item => {
			value += +item.value;
		})
		return value.toString();
	}

	setNextPlayerIndex(index) {
		document.getElementById('overLayertrans').style.display = 'block';
		setTimeout(()=> {
			const nextIndex = index < (this.game.players.length - 1) ? index + 1 : 0;
			this.setPlayer(nextIndex);
			document.getElementById('overLayertrans').style.display = 'none';
		}, 700);
	}

	setPlayer(index: number): void {
		this.game.selectedPlayerIndex = index;
	}

	toggleSuggestions(hide?:string): void {
		var items = document.querySelectorAll('[class^="suggestion-panel"]');
		for (var i = 0; i < items.length; i++) {
			if (hide) {
				items[i].innerHTML = '';
				items[i].className = 'suggestion-panel hide';
			} else {
				items[i].className.replace(' hide', ' ');
			}
		}
	}

	hidePopUp(): void {
		var items = document.getElementsByClassName('popup');

		for (var i = 0; i < items.length; i++) {
			items[i].className = 'popup hide';
			document.getElementById('overLayer').style.display = 'none';
		}
	}

	checkToggleOptions(popup: HTMLElement): void {
		if (!this.isShowDiceBoard) {
			this.toggleOptions(popup);
		}
	}

	toggleOptions(popup: HTMLElement): void {
		this.hidePopUp();

		if (popup.className == 'popup hide') {
			popup.className = 'popup';
			document.getElementById('overLayer').style.display = 'block';
			(<HTMLElement>document.getElementById('overLayer')).className = 'overLayer';
		} else {
			popup.className = 'popup hide';
			
			document.getElementById('overLayer').style.display = 'none';
		}
	}


	saveGame() {
		const date = new Date(this.game.dateTime).toString();
		const type = this.game.gameList.name;
		let names = '';
		this.game.players.forEach((player, index) => {
			names += index < this.game.players.length -1 ? player.name + ', ' : player.name;
		});
		const fileName = 'saved-game_' + date + '_' + type + '_' + names;
		this.storage.saveGame(this.game, fileName);
	}

	stopSaveGame(): void {
		this.saveGame();
		this.storage.deleteGame();
		this.storage.deleteGame('gamePrevious')
		this.storage.deleteDiceSettings('diceSettingsPrevious');
		this.hidePopUp();
		this.restartGame.emit(true);
		this.game = null;
		this.showUndo = false;
	}

	stopGame(popup: HTMLElement): void {
		popup.className = 'popup';
		document.getElementById('overLayer').style.display = 'block';
		(<HTMLElement>document.getElementById('overLayer')).className = 'overLayer';
	}

	toggleDiceBoard() {
		this.isShowDiceBoard = !this.isShowDiceBoard;
		this.showDiceBoard.emit(this.isShowDiceBoard);
		this.game.showDiceBoard = this.isShowDiceBoard;
		this.storage.saveGame(this.game);
	}

	generateGame(): void {
		this.game = {
			dateTime: new Date(),
			selectedPlayerIndex: 0,
			players: this.storage.loadPlayers(),
			gameList: this.storage.loadGameList(),
			numberOfGameLists: this.storage.loadNumberOfLists(),
		};
		this.game.gameList.listParts.forEach((part) => {
			part.listItems.forEach((item) => {
				item.playerscores = [];
				const players = this.game.players;
				players.forEach((player) => {
					let col = 1;
					let colScores: ColumnScore[] = [];
					player.currentRound = 0;
					while (col <= this.game.numberOfGameLists) {
						const colScore: ColumnScore = {
							column: col,
							value: null
						};
						colScores.push(colScore);
						col++;
					}
					const playerscore: GamePlayerColumn = {
						player: player,
						columns: colScores
					};
					item.playerscores.push(playerscore);
				});
			});
		});

		this.storage.saveGame(this.game);

		setTimeout(() => {
			this.calculateAll();
		}, this.initialCalculateTimeOut);
	}

	startGame() {
		this.isShowDiceBoard = this.game.showDiceBoard || false;
		this.showDiceBoard.emit(this.game.showDiceBoard);
		setTimeout(() => {
		   this.calculateAll();
		}, this.initialCalculateTimeOut);
	}

	ngOnInit(): void {
		this.game = this.storage.loadGame();
		this.game ? this.startGame() : this.generateGame();

		// translations
		this.translations = this.storage.loadSelectedLanguage('nl');

		//undo
		if (localStorage.getItem("gamePrevious") !== null) {
			this.showUndo = true;
		  }
	}
}
