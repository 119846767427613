<div class="throw-indicator-panel">
    <span class="throw-indicator" [ngClass]="{'active' : (diceSettings.currentThrow - 1) >= (throwIndex + 1)}" 
        *ngFor="let in of makeArray(totalThrows); let throwIndex = index">
    </span>    
</div>
<div class="row">

    <div class="col col-throw-button">
        <button class="btn" style="width:50px;text-align: center;" [disabled]="(diceSettings.currentThrow > totalThrows) || throwTime" (click)="roll(diceSettings.currentThrow)">
            <fa-icon [icon]="faThrow"></fa-icon>
        </button>
    </div>
    <div class="col flex col-dices">
        <span *ngFor="let diceValue of diceSettings.totalDices; let diceIndex = index;" class="dice dice-placeholder">
            <span *ngIf="diceSettings.thrownDices[diceIndex]" class="thrown link" (click)="holdDice(diceIndex, diceSettings.thrownDices[diceIndex])">
                <img [src]="'assets/images/dices/dice-' + diceSettings.thrownDices[diceIndex]  + '.png'">
            </span>
            <span *ngIf="diceSettings.holdDices[diceIndex]" class="hold link" (click)="releaseDice(diceIndex, diceSettings.holdDices[diceIndex])">
                <img *ngIf="diceSettings.holdDices[diceIndex]" [src]="'assets/images/dices/dice-' + diceSettings.holdDices[diceIndex]  + '.png'">
            </span>
        </span>
    </div>
    <div class="col fixed align-right">
        <button [disabled]="diceSettings.currentThrow == 1 || throwTime" class="btn btn-secondary" (click)="resetRoll()">
            <fa-icon [icon]="faReset"></fa-icon> 
        </button>
    </div>
</div>
