import { Component, OnInit, ViewChild } from '@angular/core';
import {DiceSettings, Game, List, Player, SavedGame, Translation} from './interfaces';
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from './storage.service';
import * as globalFunctions from './functions';

@Component({
  selector: 'app-root',

  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit   {

  constructor(private httpClient: HttpClient, private storage: LocalStorageService){}

  @ViewChild('popup') popupAddPlayer: HTMLElement;

  game: Game;
  savedGame: Game = null;
  savedGames: SavedGame[];
  list: List;
  players: Player[] = [];
  availableColors: string[];
  selectedColor: string;
  diceScores: DiceSettings;
  translations: JSON;

  selectedNumberOfLists = 1;

  showDiceBoard = false;
  resetDiceBoard = false;
  undoDiceBoard: boolean;
  isGameStarted = false;

  title = 'Dobbel score';
  selectedGameType = 'Yahtzee';
  colors = ['#2200e2', '#00a308', '#f3db02', '#e20101', '#f07a0c', '#ca07a0', '#a52a2a'];
  gameTypes = ['Yahtzee','Cassy'];
  defaultGameType = 'Yahtzee';

  makeArray = globalFunctions.makeArray;

  setSelectedGameType(gameType: string): void {
    this.selectedGameType = gameType;
    this.storage.saveGameType(gameType);
    this.httpClient.get("assets/config/" + gameType.toLowerCase() + ".json").subscribe((data: List) => {
      this.list = data;
      this.storage.saveGameList(this.list);
    })
  }

  addPlayer(player: HTMLInputElement): void {
    if (player.value.length > 0) {
      this.players.push({name: player.value, color: this.selectedColor});
      player.value = "";
      this.storage.savePlayers(this.players);
      this.hideAllOptions();
    }
  }

  showAddPllayer(popup: HTMLElement): void {
    popup.className = "popup";
    document.getElementById('overLayer').style.display = 'block';
    this.setAvailableColors();
    this.selectedColor = this.availableColors[0];
  }

  deletePlayer(index: number): void {
    this.players.splice(index,1);
    this.storage.savePlayers(this.players);
    this.setAvailableColors();
  }

  movePlayer(index: number): void {
    const playerToMove: Player = this.players.splice(index, 1)[0];
    this.players.splice(index + 1, 0, playerToMove);
    this.storage.savePlayers(this.players);
  }

  showMoveButton(index: number): boolean {
    return index < this.players.length -1 ? true : false;
  }

	hideAllOptions(): void {
		var items = document.getElementsByClassName('popup');
		for (var i = 0; i < items.length; i++) {
			items[i].className = 'popup hide';
			document.getElementById('overLayer').style.display = 'none';
		}
	}

  setNumberOfLists(value: number): void {
    this.selectedNumberOfLists = value;
    this.storage.saveNumberOfLists(value);
  }

  killGame(): void {
    this.savedGame = null;
    this.isGameStarted = false;
    this.showDiceBoard = false;
    this.storage.deleteDiceSettings();
    this.loadSavedGames();
  }

  selectGame(name: string): void {
    this.httpClient.get("assets/" + name + ".json").subscribe(resultList =>{

      this.list = resultList as List;
      this.storage.saveGameList(this.list);
    })  
  }

  toggleDiceBoard(showDiceBoard: boolean): void  {
    this.showDiceBoard = showDiceBoard;
  }

  setUndoDiceBoard(): void {
    this.undoDiceBoard = !this.undoDiceBoard;
  }

  setResetDiceBoard(): void {
    this.resetDiceBoard = !this.resetDiceBoard;
  }

  setColor(color: string): void {
    this.selectedColor = color;
  }

  startGame(): void{
    this.storage.savePlayers(this.players);
    this.isGameStarted = true;
  }

  showSavedGames(popup: HTMLElement): void {
    popup.className = "popup";
    document.getElementById('overLayer').style.display = 'block';
  }

  deleteSavedGame(fileName, savedGames) {
    if (confirm('Dit spel verwijderen?')) {
      this.storage.deleteGame(fileName);
      this.loadSavedGames();

      // if all savedGames are removed. Popup can be closed
      if (savedGames.length == 1) {
        this.hideAllOptions();
      }
    }
  }

  loadSavedGames(): SavedGame[] {

    let savedGames: SavedGame[] = [];

    // get all names of saved games from localstorage.
    const names = Object.keys(localStorage).filter(item => item.includes('saved-game_'));
    
    // loop throught names
    names.forEach((gameName) => {
      
      const name = gameName.replace('saved-game_','');
      const nameParts = name.split('_');
      const dateStrings = nameParts[0];
      const dateStringParts = dateStrings.split(' ');
      const date = new Date(dateStringParts[1] + ' ' + dateStringParts[2] + ' '+ dateStringParts[3] + ' ' + dateStringParts[4]); 
      const dateString = date.toLocaleString();
      const players = nameParts[2];
      const gameType = nameParts[1];
      savedGames.push({
        fileName: gameName, 
        name: gameType, 
        date: date, 
        dateString: date.toLocaleString(), 
        players: players 
      });
    });
    this.savedGames = savedGames;

    // sort by date desc
    savedGames = savedGames.sort(function (a, b) {
      return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
    });
    return savedGames;
  }

  startSavedGame(fileName: string): void {   
      this.savedGame = this.storage.loadGame(fileName);
      this.storage.saveGame(this.savedGame);
      
      if (this.savedGame) {
        this.startGame();
        this.hideAllOptions();
      }
  }

  setAvailableColors(): void {
    this.availableColors = this.colors;
    this.players.forEach((player) => {
      this.availableColors = this.availableColors.filter(color => !color.includes(player.color));
      this.selectedColor = this.availableColors[0];
    })
  }

  showDiceScores(event: DiceSettings) {
    this.diceScores = JSON.parse(JSON.stringify(event)); // Hack to let onChanges work
  }

  ngOnInit(): void {

    // load saved games 
    this.loadSavedGames();

    this.availableColors = this.colors;
    this.selectedColor = this.availableColors[0];

    // get last players
    this.players = this.storage.loadPlayers() || []; 

    // get Last played gameType
    const gameType = this.storage.loadGameType() || this.defaultGameType;
    this.setSelectedGameType(gameType);

    // load active game
    this.game = this.storage.loadGame(); 
    this.selectedNumberOfLists = this.storage.loadNumberOfLists() || 1; // get latest number of list. Default 1 
    this.storage.saveNumberOfLists(this.selectedNumberOfLists);

    // translaton
    this.translations = this.storage.loadSelectedLanguage('nl');

    // check if there is an active game. then start game directly
    if (this.game) {
      this.startGame();
    }
  }
}
