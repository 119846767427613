import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { faUser, faPlay, faUndo  } from '@fortawesome/free-solid-svg-icons';
import { DiceSettings } from '../interfaces';
import { LocalStorageService } from './../storage.service';
import * as globalFunctions from './../functions';

@Component({
	selector: 'app-roll-dice',
	templateUrl: './roll-dice.component.html',
	styleUrls: [ './roll-dice.component.scss' ]
})
export class RollDiceComponent implements OnInit, OnChanges {
	@Input() resetDiceBoard: boolean;
	@Input() undoDiceBoard: boolean;
	@Output() showDiceScores = new EventEmitter<DiceSettings>();

	constructor(private storage: LocalStorageService) {}

	totalThrows = 3;
	numberOfDices = 5;
	diceSettings: DiceSettings;
	throwTime: boolean = false;
	faUser = faUser;
	faThrow = faPlay;
	faReset = faUndo;

	translations: JSON;

	async roll(currentThrow) {
		this.throwTime = true;
		await this.rollTheDices();

		if (this.diceSettings.newStart) {
			this.resetDiceSets();
			this.diceSettings.newStart = false;
			var diceCount = 1;
			while (diceCount <= this.numberOfDices) {
				const diceValue = Math.floor(Math.random() * 6) + 1;
				this.diceSettings.thrownDices[diceCount - 1] = diceValue;
				this.diceSettings.restDices[diceCount - 1] = diceValue;
				diceCount++;
			}
		} else {
			let count = 0;
			this.diceSettings.restDices.forEach((dice) => {
				if (dice) {
					const diceValue = Math.floor(Math.random() * 6) + 1;
					this.diceSettings.thrownDices[count] = diceValue;
				}
				count++;
			});
		}

		this.diceSettings.currentThrow++;
		this.throwTime = false;

		if (currentThrow == this.totalThrows) {
			this.diceSettings.newStart = true;
		}

		this.storage.saveDiceSettings(this.diceSettings);
		this.showDiceScores.emit(this.diceSettings);
	}

	async wait(seconds) {
		return new Promise((resolve) => {
			setTimeout(resolve, seconds * 1000);
		});
	}

	async rollTheDices() {
		for (let randomDice = 1; randomDice <= 8; randomDice++) {
			await this.wait(0.07);
			for (let diceCount = 1; diceCount <= this.numberOfDices; diceCount++) {
				const diceValue = Math.floor(Math.random() * 6) + 1;
				this.diceSettings.thrownDices[diceCount - 1] = diceValue;
			}
		}
	}

	resetRoll() {
		this.resetDiceSets();
		this.diceSettings.newStart = true;
		this.storage.saveDiceSettings(this.diceSettings);
	}

	resetDiceSets() {
		this.diceSettings.currentThrow = 1;
		this.diceSettings.holdDices = this.resetDiceArray();
		this.diceSettings.thrownDices = this.resetDiceArray();
		this.diceSettings.restDices = this.resetDiceArray();
	}

	resetDiceArray() {
		let array = [];
		for (let index = 1; index <= this.numberOfDices; index++) {
			array.push(null);
		}
		return array;
	}

	releaseDice(diceIndex, diceValue) {
		this.diceSettings.thrownDices[diceIndex] = diceValue;
		this.diceSettings.holdDices[diceIndex] = null;
		this.storage.saveDiceSettings(this.diceSettings);
	}

	holdDice(diceIndex: number, diceValue: number): void {
		this.diceSettings.holdDices[diceIndex] = diceValue;
		this.diceSettings.thrownDices[diceIndex] = null;
		this.storage.saveDiceSettings(this.diceSettings);
	}

	makeArray(i: number): number[] {
		return new Array(i);
	}

	updateLocalStoreDices(): void {
		const diceSettings: DiceSettings = {
			currentThrow: this.diceSettings.currentThrow,
			holdDices: this.diceSettings.holdDices,
			thrownDices: this.diceSettings.thrownDices,
			totalDices: this.diceSettings.totalDices,
			restDices: this.diceSettings.restDices,
			newStart: this.diceSettings.newStart
		};
		this.storage.saveDiceSettings(diceSettings);
	}

	undoDiceSettings() {
    this.diceSettings = this.storage.loadDiceSettings('diceSettingsPrevious');
    this.showDiceScores.emit(this.diceSettings);
    localStorage.removeItem('diceSettingsPrevious');
    localStorage.removeItem('gamePrevious');
	}

	startDiceSettings() {
		this.diceSettings = {
			currentThrow: 1,
			newStart: false,
			totalDices: [],
			holdDices: [],
			restDices: [],
			thrownDices: []
		};
		for (let index = 1; index <= this.numberOfDices; index++) {
			this.diceSettings.totalDices.push(index);
		}

		this.storage.saveDiceSettings(this.diceSettings);
	}

	ngOnInit(): void {
		this.diceSettings = this.storage.loadDiceSettings();
		this.translations = this.storage.loadSelectedLanguage('nl');
		if (!this.diceSettings) {
			this.startDiceSettings();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.undoDiceBoard && !changes.undoDiceBoard.isFirstChange()) {
        this.undoDiceSettings();  
    }
		if (changes.resetDiceBoard) {
			this.startDiceSettings();
		}

	}
}
