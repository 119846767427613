<div id="overLayer" class="overLayer hide" (click)="hideAllOptions()"></div>

<div class="game-board-panel new-game" *ngIf="!isGameStarted">
    
    <div class="new-game-panel">
        <!-- logo-->
        <div class="logo-panel">
            <img class="logo" src="assets/images/logo.svg" >
        </div>

        <div class="game-options-panel negative-margin">
            <!-- game mode -->
            <div class="row transparent block">
                <div class="col description">Kies een spel</div>
                <div class="col">
                    <div class="item width-auto" [ngClass]="{'active' : gameType == selectedGameType}" *ngFor="let gameType of gameTypes"
                    (click)="setSelectedGameType(gameType)">{{gameType}}</div>
                </div>
            </div>

            <!-- select number of game rows-->
            <div class="row transparent block">
                <div class="col description block">Aantal rijen</div>
                <div class="col block">
                    <div class="item" [ngClass]="{'active' : (index + 1) == selectedNumberOfLists}" *ngFor="let in of makeArray(4); let index = index"
                    (click)="setNumberOfLists(index + 1)">{{index + 1}}</div>
                </div>
            </div>

            <!-- select Players-->
            <div class="row transparent block top" style="padding-bottom:5px;">
                <div class="col description block">Spelers<br><small style="font-weight: normal;">Max 5</small></div>
                    <!-- add player  when possible-->
                    <div class="col width-auto" *ngIf="players?.length < 5">
                        <button class="btn" (click)="showAddPllayer(popup)">+</button>
                    </div>
                <div class="col">
                    
                    <!-- add player popup-->
                    <div #popup id="add-player-popup" [ngClass]="{'show' : players.length == 0}" class="popup hide">
                        <div *ngIf="players?.length < 5">
                            <h4>Naam speler</h4>
                            <input class="player-input" maxlength="8" [style]="'margin-right:5px;padding:5px;width:70%;border-left:solid 5px ' + selectedColor" #newPlayer type="text"> 
                            <button class="btn" (click)="addPlayer(newPlayer)">Opslaan</button>

                            <div class="player-color-panel" style="padding-top:8px;">
                                <span [ngClass]="{'active' : selectedColor == color}" (click)="setColor(color)" #playerColor *ngFor="let color of availableColors" class="color-item" [style]="'background-color:' + color"></span>
                            </div>
                        </div>    
                    </div>

                    <!-- show players -->
                    <div class="row transparent no-border no-margin" *ngFor="let player of players; let index = index">
                        <div [id]="'player-item-' + index" class="item width-80 item-player no-margin"  [style]="'border-left:solid 8px ' + player.color">
                            {{player.name}}
                            <button class="btn delete" (click)="deletePlayer(index)">x</button>
                        </div>
                        <div>
                            <button *ngIf="showMoveButton(index)" class="btn move" (click)="movePlayer(index)">></button>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Start game -->
            <div *ngIf="players?.length == 0" class="row center column block no-border" style="margin-top:15px">
                Voeg eerst 1 of meerdere spelers toe. 
            </div>
            <div class="row center column block no-border">
                <button [disabled]="players?.length == 0" class="btn-start-game" (click)="startGame('player')">Start nieuw spel</button>
            </div>
        </div>

        <!-- Saved games-->
        <div class="game-options-panel saved-games link" *ngIf="savedGames.length > 0">
            <div (click)="showSavedGames(popupSavedgames)" class="row transparent no-border">
                Selecteer een opgeslagen spel
            </div>
            <div #popupSavedgames class="popup hide">
                <h4>Opgeslagen spellen</h4>
                <div (click)="startSavedGame(savedGame.fileName)" *ngFor="let savedGame of savedGames" class="row link">
                    <div class="col flex">
                        {{savedGame.dateString}} | {{savedGame.name}}<br>
                        {{savedGame.players}}
                    </div>
                    <div class="col width-auto">
                        <button class="btn delete" (click)="deleteSavedGame(savedGame.fileName, savedGames)">x</button>
                    </div>
                </div>    
            </div>
        </div>

    </div>
</div>

<!-- Game-panel -->
<div id="game-board-lists" [ngClass]="{'diceboard' : showDiceBoard}" class="game-board-panel" *ngIf="isGameStarted">
    <app-game-board 
        [diceScore]="diceScores"
        (restartGame)="killGame()" 
        (resetDiceBoard)="setResetDiceBoard($event)" 
        (undoDiceBoard)="setUndoDiceBoard($event)" 
        (showDiceBoard)="toggleDiceBoard($event)" >
    </app-game-board>
    <app-roll-dice [ngClass]="{'hidden' : !showDiceBoard }" 
        [resetDiceBoard]="resetDiceBoard"  
        [undoDiceBoard]="undoDiceBoard"
        (showDiceScores)="showDiceScores($event)"
        class="dice-board">
    </app-roll-dice>
</div>
