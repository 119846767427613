export interface Game {
    dateTime: Date;
    players: Player[];
    gameList: List;
    numberOfGameLists: number;
    selectedPlayerIndex?: number;
    showDiceBoard?: boolean;
    diceSettings?: DiceSettings;
}

export interface GamePlayer {
    player: Player
}

export interface GamePlayerColumn {
    player: Player;
    columns?: ColumnScore[];
    totalScore?: Number;
}

export interface ColumnScore {
    column: number;
    value?: number;
    suggestionScore?: number;
}

export interface Player {
    name: string;
    color?: string;
    currentRound?: number;
}

export interface List {
    name: string;
    listParts: ListPart[];
    numberOfDices: number;
}

export interface ListPart {
    name: string;
    listItems?: ListItem[];
    hasBonusPoints?: boolean;
    bonusTarget?: number;
    bonus?: number
    bonusValue?: number;
}

export interface ListItem {
    name: string;
    value?: number;
    calculationType?: CalculationType;
    fixedPoints?: number[];
    playerscores?: GamePlayerColumn[];
    validation?: ItemValidation;
    cssClasses?: string;
    info?: string;
}

export interface ItemValidation {
    numberOfSameDices?: number[];  // two numbers is for full house or 2 pair (i.e. [2,3] or [2,2])
    sameNumbersExactSame?: boolean; // for 1 pair, 2 pair
    possibleCombinations?: string[]; // ie. small street yathzee ["1,2,3,4", "2,3,4,5", "3,4,5,6" ] 
    countAllDices?: boolean; // default no
    fixedScore?: boolean;
}


export interface SavedGame {
    fileName: string;
    name: string;
    date?: Date;
    dateString: string;
    players?: string;
}

export interface DiceSettings {
    currentThrow: number;
    holdDices: number[];
    thrownDices: number[];
    totalDices: number[];
    restDices: number[];
    newStart: boolean;
}

export interface DicesSameValues {
    diceValue: number;
    numberOfTimes: number;
}


export interface Translation {
    key: string;
    value: string;
}

export enum CalculationType {
    numberOfEyes = 1,
    fixedNumber = 2,
}
