<div class="board-panel show" [ngClass]="{'show' : !isInitialCalc && game}">

    <div id="overLayer" class="overLayer hide" (click)="hidePopUp()"></div>
    <div id="overLayertrans" class="overLayer transparent hide"></div>

    <div class="header">
        <h2>{{game.gameList.name}}</h2>
        <button class="btn btn-diceboard" [ngClass]="{'active' : this.isShowDiceBoard}" (click)="toggleDiceBoard()">
            <fa-icon [icon]="faDice"></fa-icon>
        </button>
        <button class="btn btn-new-game" (click)="stopGame(popupHome)">
            <fa-icon [icon]="faHome"></fa-icon> Home
        </button>    
    </div>

    <div #popupHome class="popup hide">
        <h4>Stoppen</h4>
        <div class="row no-border">Weet je zeker dat je wilt stoppen?</div>
        <div class="row action-buttons">
            <button class="btn btn-primary" (click)="stopSaveGame()">Ja</button>
            <button class="btn btn-secondary" (click)="hidePopUp()">Annuleren</button>
        </div>
    </div>

    <!--players panel-->
    <div class="game-progress" #gameProgressPanel>
        <div class="player-switch-panel">
            <span [style]="'border-top:solid 3px ' + player.color" [ngClass]="{'active': selectPlayerIndex === this.game?.selectedPlayerIndex}" *ngFor="let player of game.players; let selectPlayerIndex = index"  (click)="setPlayer(selectPlayerIndex)">
                <div> {{player.name}}</div>
            </span>    
        </div>
    </div>

    <!-- Generate players-->
    <div class="player-panel" [ngClass]="{'show-panel' : playerIndex == this.game?.selectedPlayerIndex}" *ngFor="let player of game.players; let playerIndex = index">
        <div class="round-info" >
        
            <fa-icon style="font-size:14px;margin-right:10px;" [icon]="faUndo" *ngIf="showUndo && !showSuggestions" (click)="setUndoDiceBoard()"></fa-icon>
            <strong>{{this.game.players[playerIndex].currentRound}}</strong> / {{totalRounds * game.numberOfGameLists}}
        </div>    

        <!-- Generate parts-->
        <div *ngFor="let part of game.gameList.listParts; let partIndex = index" class="part">
            <div class="row part">
                <div class="col description part">
                    {{part.name}}
                </div>
            </div>
            <div class="row row-item" *ngFor="let item of part.listItems;  let itemIndex = index">
        
                <div class="col description">
                    {{item.name}} 
                </div>
                    
                <!-- Generate Columns-->
                <div id="list-field-{{playerIndex}}-{{partIndex}}-{{colIndex}}-{{itemIndex}}" 
                    [ngClass]="{'striped' : col.value == 0}"
                    *ngFor="let col of item.playerscores[playerIndex].columns; let colIndex = index " class="col col-value">
                    <input
                        id="list-field-{{playerIndex}}-{{partIndex}}-{{colIndex}}-{{itemIndex}}-value" 
                        [ngModel]="item.playerscores[playerIndex].columns[colIndex].value" 
                        readonly="true" type="text" [class]="col.value > 0 ? item.cssClasses : ''"
                        [ngClass]="{'filled' : col.value || col.value == 0, 'striped' : col.value == 0}"
                        (click)="checkToggleOptions(popupId)" />

                    <span *ngIf="col.value == null && isShowDiceBoard" class="suggestion-panel" 
                        [ngClass]="{'hide' : !showSuggestions, 'accent' : col.suggestionScore, 'striped' : col.suggestionScore == 0}"
                        (click)="fillColField(col, playerIndex, col.suggestionScore)">
                        {{col.suggestionScore == 0 ? '' : col.suggestionScore }}
                    </span>

                    <div #popupId class="popup hide">
                        <h4>{{item.name}}</h4>
                        <div *ngIf="item.info" class="info" [innerHtml]="item.info"></div>

                        <!-- Number of dices-->
                        <div *ngIf="item.calculationType == calculationType.numberOfEyes">
                            <div *ngFor="let in of makeArray(game.gameList.numberOfDices); let diceIndex = index"
                            class="dice-select-item" [ngClass]="{'active' : col.value == ((diceIndex + 1) * item.value), 'striped' : (item.value * (diceIndex + 1)) == 0}"
                            (click)="fillColField(col, playerIndex, item.value * (diceIndex + 1))">
                                {{item.value * (diceIndex + 1)}}
                            </div>
                        </div>

                        <!-- Fixed Poinst-->
                        <div *ngIf="item.calculationType == calculationType.fixedNumber">
                            <div *ngFor="let score of item.fixedPoints; let diceIndex = index"
                                class="dice-select-item" [ngClass]="{'active' : col.value == score, 'striped' : score == 0}"
                                (click)="fillColField(col, playerIndex, score )">
                                {{score}}
                            </div>
                        </div>

                        <!-- no score item-->
                        <div class="dice-select-item striped" style="margin-top:20px;" [ngClass]="{'active' : col.value == 0}"
                            (click)="fillColField(col, playerIndex, 0)" >
                            0
                        </div>
                        <div class="dice-select-item" style="margin-top:20px;width:180px;" [ngClass]="{'hide' : !showResetItem(col.value)}" 
                            (click)="resetScore(col)">Verwijder score: <strong>{{col.value}}</strong>
                        </div>

                    </div>
                </div>  
            </div>
            <div *ngIf="part.hasBonusPoints">
                <div class="row sub-total">
                    <div class="col description total sub-total">
                        Sub Totaal
                    </div>
                    <div *ngFor="let in of makeArray(game.numberOfGameLists); let colIndex = index" class="col col-value">
                        <input readonly="true" id="list-field-{{playerIndex}}-{{partIndex}}-{{colIndex}}-subtotal" value="0">
                    </div>  
                </div>
                <div class="row bonus">
                    <div class="col description">
                        Bonus <div class="remarks">Vanaf {{part.bonusTarget}} punten</div>
                    </div>
                    <div *ngFor="let in of makeArray(game.numberOfGameLists); let colIndex = index " class="col col-value">
                        <span id="list-field-{{playerIndex}}-{{partIndex}}-{{colIndex}}-subtotal-sign" class="total sign info"></span>
                        <input readonly="true" id="list-field-{{playerIndex}}-{{partIndex}}-{{colIndex}}-bonus" value="0">
                    </div>  
                </div>
            </div>
            <div class="row total">
                <div class="col description">
                    Totaal {{part.name}}
                </div>
                <div *ngFor="let in of makeArray(game.numberOfGameLists); let colIndex = index " class="col">
                    <input readonly="true" id="list-field-{{playerIndex}}-{{partIndex}}-{{colIndex}}-total" value="0">
                </div>  
            </div>
        </div>
        <div class="row total-general" [ngClass]="{'dice-board-margin' : isShowDiceBoard }">
            <div class="col description total total-general">
                TOTAAL <button class="btn btn-transparent" (click)="showPopupEndGame(true)">
                    <fa-icon [icon]="faList"></fa-icon>
                </button>
            </div>
            <div *ngFor="let in of makeArray(game.numberOfGameLists); let colIndex = index " class="col">
                <input readonly="true" id="list-field-{{playerIndex}}-{{colIndex}}-totalgeneral" value="0">
            </div>  
        </div>
    </div>
</div>

<!-- Popup game totals  -->
<div #popupEndGame id="end-game-popup" class="popup hide" >
    <h4 id="title-end-game">Spel afgelopen</h4>
    <div>
        <div class="row endgame endgame-header">
            <div class="col description">
                Speler
            </div>
            <div class="col endgame-width" *ngFor="let col of makeArray(game.numberOfGameLists); let colIndex = index">Rij {{colIndex+1}}</div> 
            <div *ngIf="this.game.numberOfGameLists > 1" class="col endgame-width">Tot.</div>
        </div>
        <div class="row endgame" *ngFor="let player of this.game.players; let playerIndex = index">
            <div class="col description">
                <span class="player-colors-endgame" [style]="'background-color:' + player.color">&nbsp;</span>
                {{player.name}}
            </div>
            <div class="col endgame-width" *ngFor="let col of makeArray(game.numberOfGameLists); let colIndex = index">
                <input class="endgame" readonly="true" id="list-field-{{colIndex}}-{{playerIndex}}-totalgeneral-end" [value]="setPopupRowTotal(playerIndex, colIndex)">
            </div>
            <div class="col endgame-width" *ngIf="game.numberOfGameLists > 1">
                <input class="endgame total bold" readonly="true" id="{{playerIndex}}-overall-total" [value]="setTotalofCols(playerIndex)">
            </div>
        </div>
    </div>
    <div class="row action-buttons">
        <button id="btn-end-game" class="btn btn-primary" (click)="stopSaveGame()">Nieuw spel</button>
        <button class="btn btn-secondary" (click)="hidePopUp()">Sluiten</button>
    </div>
</div>

